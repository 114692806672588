import { useEffect, useState } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import { type TargetingData, searchTargeting } from 'src/modules/gpt-react';
import { isClientWidthAbove } from 'src/modules/responsive-helpers';
import { useSelector } from 'src/store/react';
import { selectResultsLocation, selectSearchQuery } from 'src/store/selectors';

interface UseHoustonAdProps {
  minimumClientWidth: number;
}
const useHoustonAd = ({ minimumClientWidth }: UseHoustonAdProps) => {
  const [shouldRenderAdverts, setShouldRenderAdverts] = useState(
    isClientWidthAbove(minimumClientWidth),
  );
  const searchQuery = useSelector(selectSearchQuery);
  const location = useSelector(selectResultsLocation);
  const { locale } = useAppConfig();
  const [memorizedTargetingData, setMemorizedTargetingData] =
    useState<TargetingData>(searchTargeting(searchQuery, locale, location));

  useEffect(() => {
    const newTargetingData = searchTargeting(searchQuery, locale, location);
    if (
      JSON.stringify(newTargetingData) !==
      JSON.stringify(memorizedTargetingData)
    ) {
      setShouldRenderAdverts(false);
      setMemorizedTargetingData(newTargetingData);
    }
  }, [location, memorizedTargetingData, searchQuery, locale]);

  useEffect(() => {
    if (isClientWidthAbove(minimumClientWidth) && !shouldRenderAdverts) {
      setShouldRenderAdverts(true);
    }
  }, [shouldRenderAdverts, minimumClientWidth]);

  return {
    shouldRenderAdverts,
    targetingData: memorizedTargetingData,
  };
};

export default useHoustonAd;
