import { useTranslations } from '@vocab/react';

import { IllustratedError } from 'src/components/IllustratedError/IllustratedError';
import Magnify from 'src/components/IllustratedError/Magnify';
import type { SearchViewModelCompanySuggestion } from 'src/store/results/results';

import { BroadenCompanySearch } from '../CompanySuggestion/CompanySuggestion';

import translations from './.vocab';

interface Props {
  companySuggestion?: SearchViewModelCompanySuggestion;
}

export default ({ companySuggestion }: Props) => {
  const { t } = useTranslations(translations);
  const keywords = companySuggestion?.search?.keywords || '';
  const hasCompanySuggestion = Boolean(keywords);

  return hasCompanySuggestion ? (
    <IllustratedError
      heading={t(
        'Sorry, there are no jobs at {companySuggestion.search.keywords}.',
        { keywords },
      )}
      Illustration={Magnify}
      actionButton={
        <BroadenCompanySearch
          keywords={keywords}
          suggestionCount={companySuggestion?.count}
          variant="button"
        />
      }
      dataAutomation="company-search-zero-results"
    />
  ) : (
    <IllustratedError
      heading={t('No matching search results')}
      Illustration={Magnify}
      description={
        <>
          {t("We couldn't find anything that matched your search.")}
          <br />
          {t('Try adjusting the filters or check for spelling errors.')}
        </>
      }
      dataAutomation="searchZeroResults"
    />
  );
};
