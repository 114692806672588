import { Box } from 'braid-design-system';

import { useAppConfig } from 'src/config/appConfig';
import { useSelector } from 'src/store/react';
import { selectFeatureFlag } from 'src/store/selectors';

import HoustonAd from '../../HoustonAd/HoustonAd';

import * as styles from './SkyScraperBanner.css';

export const SkyScraperBanner = () => {
  const showMarketingAdSplitView = useSelector(
    selectFeatureFlag('showMarketingAdSplitView'),
  );
  const { country } = useAppConfig();

  return showMarketingAdSplitView ? (
    <Box className={styles.towerAdSplitView}>
      <HoustonAd
        tagId={`/23030987465/SEEK${country}/jobsearch_skyscraper2`}
        width={160}
        height={600}
        minWidth={styles.minimumDisplayWidth}
      />
    </Box>
  ) : null;
};
