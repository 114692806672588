import { Box } from 'braid-design-system';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { useAppConfig } from 'src/config/appConfig';
import { useSelector } from 'src/store/react';
import { selectFeatureFlag } from 'src/store/selectors';

import HoustonAd from '../../HoustonAd/HoustonAd';
import useHoustonAd from '../../HoustonAd/useHoustonAd';

import * as styles from './MRECBanner.css';

export const MRECBanner = () => {
  const showMarketingAdSplitView = useSelector(
    selectFeatureFlag('showMarketingAdSplitView'),
  );
  const { country } = useAppConfig();

  const [shouldShowBanner, setShouldShowBanner] = useState(false);
  const minWidth = styles.minimumDisplayWidth;
  const { shouldRenderAdverts } = useHoustonAd({
    minimumClientWidth: minWidth,
  });

  const { ref: bannerRef } = useInView({
    onChange(inView) {
      if (shouldRenderAdverts && inView) {
        setShouldShowBanner(inView);
      }
    },
    triggerOnce: shouldShowBanner && shouldRenderAdverts,
  });

  if (!showMarketingAdSplitView) {
    return null;
  }

  return (
    <Box
      ref={bannerRef}
      className={styles.mrecAdWrapper}
      data={{ automation: 'mrec-banner-wrapper' }}
    >
      {shouldShowBanner && (
        <HoustonAd
          tagId={`/23030987465/SEEK${country}/jobsearch_mrec`}
          height={250}
          width={300}
          minWidth={minWidth}
        />
      )}
    </Box>
  );
};
