
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2F2ZSB0aGlzIHNlYXJjaCIsIlNhdmluZyI6IlNhdmluZyIsIldl4oCZdmUgc2F2ZWQgeW91ciBzZWFyY2giOiJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoLiIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMuIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZC4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUuIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2ltcGFuIHBlbmNhcmlhbiBpbmkiLCJTYXZpbmciOiJNZW55aW1wYW4iLCJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoIjoiUGVuY2FyaWFuIGthbXUgdGVsYWggZGlzaW1wYW4uIiwiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMiOiJLZWxvbGEgcGVuY2FyaWFuIHlhbmcga2FtdSBzaW1wYW4iLCJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZCI6IlRlcmphZGkga2VzYWxhaGFuIGRhcmkgcGloYWsga2FtaS4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJDb2JhIGxhZ2kgbmFudGkuIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiU2ltcGFuIHBlbmNhcmlhbiBpbmkiLCJTYXZpbmciOiJNZW55aW1wYW4iLCJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoIjoiUGVuY2FyaWFuIGthbXUgdGVsYWggZGlzaW1wYW4uIiwiTWFuYWdlIHlvdXIgc2F2ZWQgc2VhcmNoZXMiOiJLZWxvbGEgcGVuY2FyaWFuIHlhbmcga2FtdSBzaW1wYW4iLCJUaGVyZSBpcyBzb21ldGhpbmcgd3Jvbmcgb24gb3VyIGVuZCI6IlRlcmphZGkga2VzYWxhaGFuIGRhcmkgcGloYWsga2FtaS4iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJDb2JhIGxhZ2kgbmFudGkuIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoi4Lia4Lix4LiZ4LiX4Li24LiB4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiZ4Li14LmJIiwiU2F2aW5nIjoi4LiB4Liz4Lil4Lix4LiH4Lia4Lix4LiZ4LiX4Li24LiBIiwiV2XigJl2ZSBzYXZlZCB5b3VyIHNlYXJjaCI6IuC5gOC4o+C4suC5hOC4lOC5ieC4muC4seC4meC4l+C4tuC4geC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4guC4reC4h+C4hOC4uOC4k+C5geC4peC5ieC4pyIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoi4LiI4Lix4LiU4LiB4Liy4Lij4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiX4Li14LmI4Lia4Lix4LiZ4LiX4Li24LiB4LmE4Lin4LmJIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiLguJ7guJrguILguYnguK3guJzguLTguJTguJ7guKXguLLguJTguJrguLLguIfguK3guKLguYjguLLguIciLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiLguIHguKPguLjguJPguLLguKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIcifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoi4Lia4Lix4LiZ4LiX4Li24LiB4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiZ4Li14LmJIiwiU2F2aW5nIjoi4LiB4Liz4Lil4Lix4LiH4Lia4Lix4LiZ4LiX4Li24LiBIiwiV2XigJl2ZSBzYXZlZCB5b3VyIHNlYXJjaCI6IuC5gOC4o+C4suC5hOC4lOC5ieC4muC4seC4meC4l+C4tuC4geC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4guC4reC4h+C4hOC4uOC4k+C5geC4peC5ieC4pyIsIk1hbmFnZSB5b3VyIHNhdmVkIHNlYXJjaGVzIjoi4LiI4Lix4LiU4LiB4Liy4Lij4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiX4Li14LmI4Lia4Lix4LiZ4LiX4Li24LiB4LmE4Lin4LmJIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiLguJ7guJrguILguYnguK3guJzguLTguJTguJ7guKXguLLguJTguJrguLLguIfguK3guKLguYjguLLguIciLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiLguIHguKPguLjguJPguLLguKXguK3guIfguK3guLXguIHguITguKPguLHguYnguIcifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiW8WgxIPEg8SD4bm94bq94bq94bq9IOG5r+G4qcOsw6zDrMWhIMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qV0iLCJTYXZpbmciOiJbxaDEg8SDxIPhub3DrMOsw6zguIHguLXguYnEo10iLCJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoIjoiW8W04bq94bq94bq94oCZ4bm94bq94bq94bq9IMWhxIPEg8SD4bm94bq94bq94bq9xowgw73DvcO9w7bDtsO2x5rHmseaxZkgxaHhur3hur3hur3Eg8SDxIPFmcOn4bipLl0iLCJNYW5hZ2UgeW91ciBzYXZlZCBzZWFyY2hlcyI6IlvhuYLEg8SD4LiB4Li14LmJxIPEg8Sj4bq94bq9IMO9w73DtsO2x5rHmsWZIMWhxIPEg+G5veG6veG6vcaMIMWh4bq94bq9xIPEg8WZw6fhuKnhur3hur3FoS5dIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJb4bmu4bip4bq94bq94bq9xZnhur3hur3hur0gw6zDrMOsxaEgxaHDtsO2w7ZtzILhur3hur3hur3hua/huKnDrMOsw6zguIHguLXguYnEoyDFtcWZw7bDtsO24LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMO2w7bDtseax5rHmsWZIOG6veG6veG6veC4geC4teC5icaMLl0iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJb4bmuxZnDvcO9w70gxIPEg8SDxKPEg8SDxIPDrMOsw6zguIHguLXguYkgw6zDrMOs4LiB4Li14LmJIMSDxIPEgyDGmsOsw6zDrOG5r+G5r8aa4bq94bq94bq9IMW14bipw6zDrMOsxprhur3hur3hur0uXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlIHRoaXMgc2VhcmNoIjoiW8WgxIPEg8SD4bm94bq94bq94bq9IOG5r+G4qcOsw6zDrMWhIMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qV0iLCJTYXZpbmciOiJbxaDEg8SDxIPhub3DrMOsw6zguIHguLXguYnEo10iLCJXZeKAmXZlIHNhdmVkIHlvdXIgc2VhcmNoIjoiW8W04bq94bq94bq94oCZ4bm94bq94bq94bq9IMWhxIPEg8SD4bm94bq94bq94bq9xowgw73DvcO9w7bDtsO2x5rHmseaxZkgxaHhur3hur3hur3Eg8SDxIPFmcOn4bipLl0iLCJNYW5hZ2UgeW91ciBzYXZlZCBzZWFyY2hlcyI6IlvhuYLEg8SD4LiB4Li14LmJxIPEg8Sj4bq94bq9IMO9w73DtsO2x5rHmsWZIMWhxIPEg+G5veG6veG6vcaMIMWh4bq94bq9xIPEg8WZw6fhuKnhur3hur3FoS5dIiwiVGhlcmUgaXMgc29tZXRoaW5nIHdyb25nIG9uIG91ciBlbmQiOiJb4bmu4bip4bq94bq94bq9xZnhur3hur3hur0gw6zDrMOsxaEgxaHDtsO2w7ZtzILhur3hur3hur3hua/huKnDrMOsw6zguIHguLXguYnEoyDFtcWZw7bDtsO24LiB4Li14LmJxKMgw7bDtsO24LiB4Li14LmJIMO2w7bDtseax5rHmsWZIOG6veG6veG6veC4geC4teC5icaMLl0iLCJUcnkgYWdhaW4gaW4gYSBsaXR0bGUgd2hpbGUiOiJb4bmuxZnDvcO9w70gxIPEg8SDxKPEg8SDxIPDrMOsw6zguIHguLXguYkgw6zDrMOs4LiB4Li14LmJIMSDxIPEgyDGmsOsw6zDrOG5r+G5r8aa4bq94bq94bq9IMW14bipw6zDrMOsxprhur3hur3hur0uXSJ9!"
        )
      )
      });
  
      export { translations as default };
    