import { useSavedJobsData } from './utils/useSavedJobsData';
import {
  type UseToggleSaveJobProps,
  useToggleSavedJob,
} from './utils/useToggleSavedJob';

const noop = () => {};

export const useSaveJobJDV = ({
  jobId,
  jobTracking,
  solMetadataReference,
}: Pick<
  UseToggleSaveJobProps,
  'jobId' | 'jobTracking' | 'solMetadataReference'
>) => {
  const jobDetailsPersonalised = useSavedJobsData({ isJobDetailsView: true });

  const { toggleSavedJob, isApplied, isSaved } = useToggleSavedJob({
    jobId,
    view: 'jobDetails',
    linkPosition: 'job details',
    solMetadataReference,
    jobTracking,
    savedJobsData: jobDetailsPersonalised?.data,
  });

  return {
    isApplied,
    isSaved,
    toggleSavedJob: isApplied ? noop : toggleSavedJob,
  };
};
