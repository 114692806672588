
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUmVsYXRlZCBzZWFyY2hlcyIsImpvYnMiOiJqb2JzIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUGVuY2FyaWFuIHRlcmthaXQiLCJqb2JzIjoicGVrZXJqYWFuIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiUGVuY2FyaWFuIHRlcmthaXQiLCJqb2JzIjoicGVrZXJqYWFuIiwiUmVmaW5lIGJ5IHJvbGUgdGl0bGUiOiJSZWZpbmUgYnkgcm9sZSB0aXRsZSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoi4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiX4Li14LmI4LmA4LiB4Li14LmI4Lii4Lin4LiC4LmJ4Lit4LiHIiwiam9icyI6IuC4h+C4suC4mSIsIlJlZmluZSBieSByb2xlIHRpdGxlIjoiUmVmaW5lIGJ5IHJvbGUgdGl0bGUifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoi4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiX4Li14LmI4LmA4LiB4Li14LmI4Lii4Lin4LiC4LmJ4Lit4LiHIiwiam9icyI6IuC4h+C4suC4mSIsIlJlZmluZSBieSByb2xlIHRpdGxlIjoiUmVmaW5lIGJ5IHJvbGUgdGl0bGUifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiW8WY4bq94bq94bq9xprEg8SDxIPhua/hur3hur3hur3GjCDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKnhur3hur3hur3FoV0iLCJqb2JzIjoiW8S1w7bDtsO2w5/FoV0iLCJSZWZpbmUgYnkgcm9sZSB0aXRsZSI6IlvFmOG6veG6vcaSw6zDrOC4geC4teC5ieG6veG6vSDDn8O9w70gxZnDtsO2xprhur3hur0g4bmvw6zDrOG5r8aa4bq94bq9XSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWxhdGVkIFNlYXJjaGVzIjoiW8WY4bq94bq94bq9xprEg8SDxIPhua/hur3hur3hur3GjCDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKnhur3hur3hur3FoV0iLCJqb2JzIjoiW8S1w7bDtsO2w5/FoV0iLCJSZWZpbmUgYnkgcm9sZSB0aXRsZSI6IlvFmOG6veG6vcaSw6zDrOC4geC4teC5ieG6veG6vSDDn8O9w70gxZnDtsO2xprhur3hur0g4bmvw6zDrOG5r8aa4bq94bq9XSJ9!"
        )
      )
      });
  
      export { translations as default };
    