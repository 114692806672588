import type { DisplayType } from '@seek/chalice-types';
import { JOB_DISPLAY_TYPE_QUERY_KEY } from '@seek/job-details-view';
import { hrefFromLocation } from '@seek/seek-jobs-seo';
import { parse } from 'query-string';
import { useMemo } from 'react';

import useSolMetadataReference from 'src/modules/hooks/useSolMetadataReference';
import type { JobDetails } from 'src/store/jobdetails/jobdetails';
import { useSelector } from 'src/store/react';

export const useLocationJobDisplayType = (): DisplayType => {
  const locationDisplayType = useSelector(
    (state) => state.location.query[JOB_DISPLAY_TYPE_QUERY_KEY],
  );
  const queryDisplayType =
    typeof window !== 'undefined'
      ? parse(window.location.search)[JOB_DISPLAY_TYPE_QUERY_KEY]
      : '';
  return useMemo<DisplayType>(
    () => queryDisplayType || locationDisplayType,
    [queryDisplayType, locationDisplayType],
  );
};

export const useJobDetails = (jobDetails: JobDetails) => {
  const displayType = useLocationJobDisplayType();
  const jobId = jobDetails?.job?.id;

  const solMetadataReference = useSolMetadataReference({
    jobId: Number(jobId),
    displayType,
  });

  const jobUrl = useMemo(() => {
    if (!jobId) {
      return '';
    }
    const query = jobId ? { [JOB_DISPLAY_TYPE_QUERY_KEY]: displayType } : {};

    // hrefFromLocation returns type: any :(
    const href = hrefFromLocation({
      pathname: `/job/${jobId}`,
      query,
      ...(solMetadataReference
        ? {
            hash: `sol=${solMetadataReference}`,
          }
        : {}),
    });

    return href as string;
  }, [jobId, displayType, solMetadataReference]);

  return { solMetadataReference, jobUrl };
};
