import {
  type ApolloClient,
  type NormalizedCacheObject,
  useApolloClient,
} from '@apollo/client';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import { useLocationJobDisplayType } from 'src/components/JobDetailsPage/useJobDetails';
import { useSplitViewContext } from 'src/handlers/SplitViewContext';
import {
  createGetSolMetadataForJDP,
  trackJobDetailsLoaded as getTrackJobDetailsLoaded,
} from 'src/hooks/trackJobDetailsLoaded';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import useSolMetadataReference from 'src/modules/hooks/useSolMetadataReference';
import { useSelector, useStore } from 'src/store/react';
import {
  selectAuthenticated,
  selectJobDetailsPersonalised,
  selectJobDetailsResult,
  selectQuery,
  selectSearchQuery,
} from 'src/store/selectors';

export const useTrackJobDetailsLoaded = ({
  isLoading,
  isError,
  isExpired,
}: {
  isLoading?: boolean;
  isError?: boolean;
  isExpired?: boolean;
}) => {
  const { selectedJobPosition, eventTrigger, selectedJob } =
    useSplitViewContext();
  const isAuthenticated = useSelector(selectAuthenticated);
  const jobDetailsPersonalised = useSelector(selectJobDetailsPersonalised);
  const analyticsFacade = useAnalyticsFacade();
  const query = useSelector(selectQuery);
  const experiments = useSelector((state) => state.experiments);
  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const currentJobDetails = useSelector(selectJobDetailsResult);
  const { savedsearchid: savedSearchId = '' } = useSelector(selectSearchQuery);
  const jobListingType = currentJobDetails?.job?.tracking?.adProductType as
    | string
    | undefined;
  const locationHash = useSelector((state) => state.location.hash);
  const jobId = currentJobDetails?.job?.id;
  const displayType = useLocationJobDisplayType();
  const store = useStore();
  const state = store.getState();

  const solMetadataReference = useSolMetadataReference({
    jobId: Number(jobId),
    displayType,
  });

  const isJobDetailsTrackingReadyToCall =
    Number(currentJobDetails?.job.id) === selectedJob?.id &&
    ((!isAuthenticated && !isLoading && !isError && !isExpired) ||
      (!isLoading && jobDetailsPersonalised && !isError && !isExpired));

  const trackJobDetailsLoaded = useCallback(() => {
    getTrackJobDetailsLoaded(
      analyticsFacade,
      {
        correlationId: uuid(),
        jobTags: selectedJob?.tags,
        jobDetails: currentJobDetails,
        jobViewType: 'split',
        jobListingPosition: selectedJobPosition,
        jobListingType,
        getSolMetadata: createGetSolMetadataForJDP({
          experiments,
          hash: locationHash,
          jobId,
          query: {
            ...query,
            sol: solMetadataReference,
          },
        }),
        jobViewOrigin: savedSearchId ? 'savedsearch' : 'search-split',
      },
      state,
      apolloClient,
      eventTrigger,
    );
  }, [
    analyticsFacade,
    apolloClient,
    currentJobDetails,
    eventTrigger,
    experiments,
    jobId,
    jobListingType,
    locationHash,
    query,
    savedSearchId,
    selectedJob?.tags,
    selectedJobPosition,
    solMetadataReference,
    state,
  ]);

  return {
    isJobDetailsTrackingReadyToCall,
    trackJobDetailsLoaded,
  };
};
