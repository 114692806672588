import { useTranslations } from '@vocab/react';

import translations from '../.vocab';

const useSalaryDisplayLabel = ({
  salary,
  currencyLabel,
}: {
  salary?: string;
  currencyLabel?: string;
}): undefined | string => {
  const { t } = useTranslations(translations);

  if (!salary) {
    return undefined;
  }

  return currencyLabel
    ? t('Salary display label', { salary, currencyLabel })
    : salary;
};

export default useSalaryDisplayLabel;
