import { useTranslations } from '@vocab/react';
import { Box, IconHelp, Text } from 'braid-design-system';
import type { breakpoints } from 'braid-design-system/css';
import { Fragment } from 'react';

import { TextLink } from 'src/components/NavLink/NavLink';
import { useDispatchDidYouMeanClicked } from 'src/store/search/search';
import type { SearchViewModelSuggestions } from 'src/types/globals';

import translations from './.vocab';

interface DidYouMeanProps {
  locations: SearchViewModelSuggestions;
  /** The breakpoint at which to collapse from sentence form, into a stack of links */
  collapseBelow?: Exclude<keyof typeof breakpoints, 'mobile'>;
}

const DidYouMean = ({
  locations,
  collapseBelow = 'tablet',
}: DidYouMeanProps) => {
  const { t } = useTranslations(translations);
  const onClick = useDispatchDidYouMeanClicked();

  return (
    <Text size="small" icon={<IconHelp />} data={{ automation: 'didYouMean' }}>
      <Box
        component="span"
        display={{ mobile: 'block', [collapseBelow]: 'inline' }}
      >
        {t('Did you mean')}{' '}
      </Box>
      {locations.map((location, index: number) => (
        <Fragment key={location.label}>
          <Box
            component="span"
            display={{ mobile: 'block', [collapseBelow]: 'inline' }}
            paddingTop={{ mobile: 'xxsmall', [collapseBelow]: 'none' }}
          >
            <TextLink
              location={{
                pathname: '/jobs',
                query: location.refineParams,
              }}
              hitArea="large"
              onClick={onClick}
              data-automation={`didYouMeanLocation${index}`}
            >
              {location.label}
            </TextLink>
          </Box>

          {index < locations.length - 1 ? (
            <Box
              component="span"
              display={{ mobile: 'none', [collapseBelow]: 'inlineBlock' }}
              paddingX="xxsmall"
            >
              {' '}
              {t('or')}{' '}
            </Box>
          ) : null}
        </Fragment>
      ))}
    </Text>
  );
};

export default DidYouMean;
