import { useTranslations } from '@vocab/react';
import { Box, Hidden, Text } from 'braid-design-system';
import { useCallback, useState, type ComponentProps } from 'react';
import { useSelector } from 'react-redux';

import { useZoneFeatures } from 'src/config/appConfig';
import type { Banner } from 'src/graphql/graphql';
import { selectBanner } from 'src/store/selectors';

import translations from './.vocab';
import { FooterLmis } from './FooterLmis/FooterLmis';
import RefineByLocation, {
  useShouldRenderSuburbs,
} from './RefineByLocation/RefineByLocation';
import { SEOBlurb } from './SEOBlurb';
import { SEOFooterRowLayout } from './SEOFooterLayout/SEOFooterRowLayout';
import { SEOPartnerSites } from './SEOPartnerSites';
import { ToggleButton } from './ToggleButton';
import { useSEOBlurb } from './useSEOBlurb';
import useSeoFooter from './useSeoFooter';

const SeoFooter = () => {
  const { LMIS_ENABLED } = useZoneFeatures();
  const banner: Banner = useSelector(selectBanner);
  const { t } = useTranslations(translations);
  const renderedBlurb = useSEOBlurb();
  const shouldRenderBlurb = renderedBlurb !== null;
  const { relatedList, shouldRenderSeoPartners } = useSeoFooter();

  const [areRowsCollapse, setAreRowsCollapse] = useState(true);
  const toggleRowCollapse = useCallback(() => {
    setAreRowsCollapse((x) => !x);
  }, [setAreRowsCollapse]);
  const {
    shouldShow: shouldRenderUnifiedLocationSuburbs,
    isSuburbsOverflow: isLocationSuburbsOverflow,
  } = useShouldRenderSuburbs();

  const shouldShowLmis = Boolean(
    LMIS_ENABLED && banner?.template?.items.length,
  );

  const shouldRenderTabletContent =
    shouldRenderBlurb ||
    shouldShowLmis ||
    shouldRenderUnifiedLocationSuburbs ||
    shouldRenderSeoPartners;

  if (!relatedList && !shouldRenderTabletContent) {
    return null;
  }

  const rowCount =
    Number(shouldRenderUnifiedLocationSuburbs) +
    Number(shouldShowLmis) +
    Number(shouldRenderBlurb) +
    Number(shouldRenderSeoPartners);

  const footerRows: Array<ComponentProps<typeof SEOFooterRowLayout>> = [];

  if (shouldRenderUnifiedLocationSuburbs) {
    footerRows.push({
      automation: 'suburbs-container',
      content: <RefineByLocation shouldDisplayAll={!areRowsCollapse} />,
      label: <Text size="small">{t('Refine by location')}</Text>,
    });
  }

  if (shouldShowLmis) {
    footerRows.push({
      automation: 'courses-container',
      content: (
        <FooterLmis
          shouldDisplayAll={!areRowsCollapse}
          items={banner.template?.items}
        />
      ),
      label: <Text size="small">{banner.template?.heading?.text}</Text>,
    });
  }

  if (shouldRenderSeoPartners) {
    footerRows.push({
      automation: 'seo-partners',
      content: <SEOPartnerSites />,
      label: <Text size="small">{t('Partner sites')}</Text>,
    });
  }

  if (shouldRenderBlurb) {
    footerRows.push({
      automation: 'sub-classifications',
      content: <SEOBlurb />,
    });
  }

  if (rowCount > 1 || isLocationSuburbsOverflow) {
    footerRows.push({
      automation: 'seo-footer-toggle-button',
      content: (
        <ToggleButton
          isCollapsed={areRowsCollapse}
          onClick={toggleRowCollapse}
        />
      ),
      hidden: false,
    });
  }

  return (
    <Box>
      {shouldRenderTabletContent && (
        <Hidden below="tablet">
          <Box component="section" paddingTop="xxlarge">
            <Box display="flex" flexDirection="column" justifyContent="center">
              {footerRows.map(({ hidden, ...row }, index) => (
                <SEOFooterRowLayout
                  {...row}
                  hidden={
                    hidden !== undefined
                      ? hidden
                      : index !== 0 && areRowsCollapse
                  }
                  key={`seo-footer-${index}`}
                />
              ))}
            </Box>
          </Box>
        </Hidden>
      )}
    </Box>
  );
};

export default SeoFooter;
