import { Box, Heading, Inline, Stack, Text } from 'braid-design-system';
import type { ElementType, ReactElement, ReactNode } from 'react';

interface Props {
  heading: string;
  Illustration: ElementType;
  description?: ReactNode;
  actionButton?: ReactElement;
  dataAutomation?: string;
}

export const IllustratedError = ({
  heading,
  description,
  Illustration,
  dataAutomation,
  actionButton,
}: Props) => (
  <Box component="section" padding="large" data-automation={dataAutomation}>
    <Stack space="large" align="center">
      <Illustration />
      <Heading level="3" align="center">
        {heading}
      </Heading>
      {description ? (
        <Text tone="secondary" align="center">
          {description}
        </Text>
      ) : null}
      {Boolean(actionButton) ? (
        <Inline space="none">{actionButton}</Inline>
      ) : null}
    </Stack>
  </Box>
);
