import debounce from 'lodash/debounce';
import { memo, useEffect, useState } from 'react';
import hash from 'string-hash';

import type { TargetingData } from './targeting/search';

type TGooglePublisherTag = typeof googletag & {
  slots: Record<string, any>;
};

const initGpt = (): TGooglePublisherTag => {
  if (window.googletag?.pubadsReady) {
    return window.googletag as TGooglePublisherTag;
  }

  const googletag = (window.googletag = {
    cmd: [],
    slots: {},
  } as unknown as TGooglePublisherTag);

  googletag.cmd.push(() => {
    googletag.pubads().collapseEmptyDivs();
    googletag.pubads().disableInitialLoad();
    googletag.pubads().setTargeting('allowtransparency', '');
    googletag.enableServices();
  });

  (() => {
    const gads = document.createElement('script');

    gads.async = true;
    gads.type = 'text/javascript';
    gads.src = 'https://www.googletagservices.com/tag/js/gpt.js';

    const head = document.getElementsByTagName('head')[0];

    head.appendChild(gads);
  })();

  return googletag;
};

interface RefreshAdProps extends GooglePublisherTagProps {
  gt: TGooglePublisherTag;
  tagName: string;
}
const refreshAd = ({
  gt,
  tagName,
  tagId,
  width,
  height,
  targetingData,
}: RefreshAdProps) => {
  if (gt && tagName && width && height) {
    gt.cmd.push(() => {
      if (!gt.slots[tagName]) {
        gt.slots[tagName] = gt
          ?.defineSlot(tagId, [width, height], tagName)
          ?.addService(gt.pubads());
        gt.display(tagName);
      }

      gt.slots[tagName].clearTargeting();

      if (targetingData && Array.isArray(targetingData)) {
        targetingData.map(({ key, value }) => {
          if (key === 'experiment') {
            if (gt) {
              gt.pubads().setTargeting(key, value);
            }
          }
          gt.slots[tagName].setTargeting(key, value);
        });
      }

      gt.pubads().refresh([gt.slots[tagName]]);
    });
  }
};

interface GooglePublisherTagProps {
  tagId: string;
  width: number;
  height: number;
  targetingData: TargetingData;
}

const DEFAULT_DEBOUNCE = 500;
const debounceRefreshAd = debounce(refreshAd, DEFAULT_DEBOUNCE);

const GooglePublisherTagView: React.FC<GooglePublisherTagProps> = (props) => {
  const { height, tagId, targetingData, width } = props;
  const [gpt, setGpt] = useState<TGooglePublisherTag | null>(null);
  const tagName = hash(tagId).toString();

  useEffect(() => {
    setGpt(initGpt());
  }, [setGpt]);

  useEffect(() => {
    if (gpt) {
      debounceRefreshAd({
        gt: gpt,
        tagName,
        targetingData,
        height,
        tagId,
        width,
      });
    }
  }, [gpt, tagName, targetingData, height, tagId, width]);

  return <div data-automation={`ad-${tagId}`} id={tagName} />;
};

export const GooglePublisherTag = memo(GooglePublisherTagView);
