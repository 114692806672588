
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiVGhpcyBpcyBhIGZlYXR1cmVkIGpvYiIsIkZlYXR1cmVkIjoiRmVhdHVyZWQiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJhdCJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiSW5pIGFkYWxhaCBsb3dvbmdhbiBrZXJqYSB1bmdndWxhbiIsIkZlYXR1cmVkIjoiVW5nZ3VsYW4iLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJJbmkgYWRhbGFoIGxvd29uZ2FuIGtlcmphIHt3b3JrVHlwZX0iLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJkaSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiSW5pIGFkYWxhaCBsb3dvbmdhbiBrZXJqYSB1bmdndWxhbiIsIkZlYXR1cmVkIjoiVW5nZ3VsYW4iLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJJbmkgYWRhbGFoIGxvd29uZ2FuIGtlcmphIHt3b3JrVHlwZX0iLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6IntzYWxhcnl9ICh7Y3VycmVuY3lMYWJlbH0pIiwiYXQiOiJkaSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoi4LiZ4Li14LmI4LiE4Li34Lit4LiH4Liy4LiZ4LmA4LiU4LmI4LiZ4LiX4Li14LmI4LmB4LiZ4Liw4LiZ4LizIiwiRmVhdHVyZWQiOiLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYHguJnguLDguJnguLMiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiLguJnguLXguYjguITguLfguK3guIfguLLguJl7d29ya1R5cGV9IiwiU2FsYXJ5IGRpc3BsYXkgbGFiZWwiOiJ7c2FsYXJ5fSAoe2N1cnJlbmN5TGFiZWx9KSIsImF0Ijoi4LiX4Li14LmIIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoi4LiZ4Li14LmI4LiE4Li34Lit4LiH4Liy4LiZ4LmA4LiU4LmI4LiZ4LiX4Li14LmI4LmB4LiZ4Liw4LiZ4LizIiwiRmVhdHVyZWQiOiLguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYHguJnguLDguJnguLMiLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiLguJnguLXguYjguITguLfguK3guIfguLLguJl7d29ya1R5cGV9IiwiU2FsYXJ5IGRpc3BsYXkgbGFiZWwiOiJ7c2FsYXJ5fSAoe2N1cnJlbmN5TGFiZWx9KSIsImF0Ijoi4LiX4Li14LmIIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiW+G5ruG4qcOsw6zDrMWhIMOsw6zDrMWhIMSDxIPEgyDGkuG6veG6veG6vcSDxIPEg+G5r8eax5rHmsWZ4bq94bq94bq9xowgxLXDtsO2w7bDn10iLCJGZWF0dXJlZCI6IlvGkeG6veG6vcSDxIPhua/HmseaxZnhur3hur3GjF0iLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJb4bmu4bipw6zDrMOsxaEgw6zDrMOsxaEgxIPEg8SDIHt3b3JrVHlwZX0gxLXDtsO2w7bDn10iLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6Ilt7c2FsYXJ5fSAoe2N1cnJlbmN5TGFiZWx9KV0iLCJhdCI6IlvEg8SD4bmvXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGlzIGEgZmVhdHVyZWQgam9iIjoiW+G5ruG4qcOsw6zDrMWhIMOsw6zDrMWhIMSDxIPEgyDGkuG6veG6veG6vcSDxIPEg+G5r8eax5rHmsWZ4bq94bq94bq9xowgxLXDtsO2w7bDn10iLCJGZWF0dXJlZCI6IlvGkeG6veG6vcSDxIPhua/HmseaxZnhur3hur3GjF0iLCJUaGlzIGlzIGEge3dvcmtUeXBlfSBqb2IiOiJb4bmu4bipw6zDrMOsxaEgw6zDrMOsxaEgxIPEg8SDIHt3b3JrVHlwZX0gxLXDtsO2w7bDn10iLCJTYWxhcnkgZGlzcGxheSBsYWJlbCI6Ilt7c2FsYXJ5fSAoe2N1cnJlbmN5TGFiZWx9KV0iLCJhdCI6IlvEg8SD4bmvXSJ9!"
        )
      )
      });
  
      export { translations as default };
    