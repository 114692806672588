import { useTranslations } from '@vocab/react';
import {
  Box,
  IconLocation,
  Inline,
  Text,
  useResponsiveValue,
} from 'braid-design-system';
import filter from 'lodash/filter';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ReactNode,
} from 'react';

import { getRefineSearchQuery } from 'src/components/Search/utils/utils';
import LinkSelect from 'src/components/SearchResultPage/SearchResultSummary/LinkSelect/LinkSelect';
import { useAppConfig } from 'src/config/appConfig';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { getDistances } from 'src/modules/refine-job-search';
import { useSelector } from 'src/store/react';
import { selectSolMetadataLocationDistance } from 'src/store/results/results';
import {
  selectIsRadialFilterShown,
  selectResultsLocationDescription,
  selectSearchQuery,
} from 'src/store/selectors';
import type { Option } from 'src/types/globals';

import translations from './.vocab';

interface DistanceFromProps {
  children?: ReactNode;
}

const DistanceFrom = ({ children }: DistanceFromProps) => {
  const { t } = useTranslations(translations);

  const analyticsFacade = useAnalyticsFacade();
  const sendOpenDropdownToAnalytics = useCallback(() => {
    analyticsFacade.radialFilterClicked();
  }, [analyticsFacade]);

  const query = getRefineSearchQuery(useSelector(selectSearchQuery));
  const { distance } = query;

  const locationDescription = useSelector(selectResultsLocationDescription);
  const serverDefaultDistance = useSelector(selectSolMetadataLocationDistance);
  const showComponent = useSelector(selectIsRadialFilterShown);
  const { zone, language } = useAppConfig();

  const labelAndValueList = getDistances(zone, language);

  const defaultDistance = serverDefaultDistance?.toString() || '50';
  const NO_DISTANCE = '';

  const [activeDistance, setActiveDistance] = useState(defaultDistance);

  useEffect(() => {
    const activeDistanceNeedsUpdate = distance !== activeDistance;
    if (distance && activeDistanceNeedsUpdate) {
      setActiveDistance(distance);
    } else if (!distance && activeDistanceNeedsUpdate) {
      setActiveDistance(defaultDistance);
    }
  }, [distance, activeDistance, defaultDistance]);

  const supportedOptionValues = labelAndValueList.map((item) => item.value);

  // If the distance is not supported, do not show any distance
  const activeDistanceValue = useMemo(
    () =>
      supportedOptionValues.includes(activeDistance)
        ? activeDistance
        : NO_DISTANCE,
    [activeDistance, NO_DISTANCE, supportedOptionValues],
  );

  const options = useMemo(
    (): Option[] =>
      labelAndValueList.map((item) => ({
        ...item,
        isActive: item.value === activeDistanceValue,
        refineParams: { ...query, distance: item.value },
      })),
    [activeDistanceValue, labelAndValueList, query],
  );

  const onChangeFn = (value: string) => {
    setActiveDistance(value);
  };

  const isMobile =
    useResponsiveValue()({
      mobile: true,
      tablet: false,
    }) || false;

  if (!showComponent || options.length === 0) {
    return (
      <Text size="small" icon={<IconLocation />}>
        {children}
      </Text>
    );
  }

  const isExactLocationOnly = filter(options, 'isActive')[0]?.value === '0';

  return activeDistanceValue !== NO_DISTANCE ? (
    <Box data={{ automation: 'distance-from-link-select' }}>
      <Inline space="none">
        <LinkSelect
          menuAlignment={isMobile ? 'left' : 'right'}
          elementType="distance"
          icon={<IconLocation />}
          label={`${
            isExactLocationOnly
              ? t('Showing jobs at')
              : t('Showing jobs within')
          } `} // Be wary of the trailing space
          navigationLabel={`${
            isExactLocationOnly ? t('Search at') : t('Search within')
          } `} // Be wary of the trailing space
          options={options}
          postDropdownText={
            isExactLocationOnly
              ? `${t('for {locationDescription}', { locationDescription })}`
              : `${t('of {locationDescription}', { locationDescription })}`
          }
          openDropdownHandler={sendOpenDropdownToAnalytics}
          onChange={onChangeFn}
        />
      </Inline>
    </Box>
  ) : null;
};

export default DistanceFrom;
