
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiVGhpcyBqb2IgaXMgbm8gbG9uZ2VyIGF2YWlsYWJsZSIsIlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiI6IlNlbGVjdCBhbm90aGVyIGpvYiBvciB0cnkgYSBkaWZmZXJlbnQgc2VhcmNoLiIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iLCJSZWxvYWQiOiJSZWxvYWQifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiTG93b25nYW4ga2VyamEgaW5pIHRpZGFrIGxhZ2kgdGVyc2VkaWEiLCJTZWxlY3QgYW5vdGhlciBqb2Igb3IgdHJ5IGEgZGlmZmVyZW50IHNlYXJjaC4iOiJQaWxpaCBsb3dvbmdhbiBsYWluIGF0YXUgY29iYSBwZW5jYXJpYW4gbGFpbi4iLCJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiTG93b25nYW4ga2VyamEgaW5pIHRpZGFrIGRhcGF0IGRpbXVhdCIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJTYWF0IGluaSBrYW1pIHRpZGFrIGRhcGF0IG1lbXVhdG55YSwgZGFuIHNlZGFuZyBiZXJ1c2FoYSBtZW1wZXJiYWlraW55YS4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJDb2JhIG11YXQgdWxhbmcgYXRhdSBwaWxpaCBsb3dvbmdhbiBrZXJqYSBsYWluLiIsIlJlbG9hZCI6Ik11YXQgdWxhbmcifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiTG93b25nYW4ga2VyamEgaW5pIHRpZGFrIGxhZ2kgdGVyc2VkaWEiLCJTZWxlY3QgYW5vdGhlciBqb2Igb3IgdHJ5IGEgZGlmZmVyZW50IHNlYXJjaC4iOiJQaWxpaCBsb3dvbmdhbiBsYWluIGF0YXUgY29iYSBwZW5jYXJpYW4gbGFpbi4iLCJXZSBjb3VsZG4ndCBsb2FkIHRoaXMgam9iIjoiTG93b25nYW4ga2VyamEgaW5pIHRpZGFrIGRhcGF0IGRpbXVhdCIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiJTYWF0IGluaSBrYW1pIHRpZGFrIGRhcGF0IG1lbXVhdG55YSwgZGFuIHNlZGFuZyBiZXJ1c2FoYSBtZW1wZXJiYWlraW55YS4iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJDb2JhIG11YXQgdWxhbmcgYXRhdSBwaWxpaCBsb3dvbmdhbiBrZXJqYSBsYWluLiIsIlJlbG9hZCI6Ik11YXQgdWxhbmcifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoi4LmE4Lih4LmI4Lie4Lia4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiZ4Li14LmJ4LmD4LiZ4Lij4Liw4Lia4Lia4LmB4Lil4LmJ4LinIiwiU2VsZWN0IGFub3RoZXIgam9iIG9yIHRyeSBhIGRpZmZlcmVudCBzZWFyY2guIjoi4LmA4Lil4Li34Lit4LiB4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmD4Lir4Lih4LmI4Lir4Lij4Li34Lit4Lil4Lit4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4Lit4Li34LmI4LiZIiwiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiI6IuC5gOC4o+C4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o+C4luC5geC4quC4lOC4h+C4leC4s+C5geC4q+C4meC5iOC4h+C4h+C4suC4meC4meC4teC5ieC5hOC4lOC5iSIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiLguYDguKPguLLguYTguKHguYjguKrguLLguKHguLLguKPguJbguYHguKrguJTguIfguKvguJnguYnguLLguJnguLXguYnguYTguJTguYkg4LiC4LiT4Liw4LiZ4Li14LmJ4Lit4Lii4Li54LmI4LmD4LiZ4Lij4Liw4Lir4Lin4LmI4Liy4LiH4LiU4Liz4LmA4LiZ4Li04LiZ4LiB4Liy4Lij4LmB4LiB4LmJ4LmE4LiCIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoi4LmC4Lir4Lil4LiU4LmD4Lir4Lih4LmI4Lir4Lij4Li34Lit4LmA4Lil4Li34Lit4LiB4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4Lit4Li34LmI4LiZIiwiUmVsb2FkIjoi4Lil4Lit4LiH4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiHIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoi4LmE4Lih4LmI4Lie4Lia4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LiZ4Li14LmJ4LmD4LiZ4Lij4Liw4Lia4Lia4LmB4Lil4LmJ4LinIiwiU2VsZWN0IGFub3RoZXIgam9iIG9yIHRyeSBhIGRpZmZlcmVudCBzZWFyY2guIjoi4LmA4Lil4Li34Lit4LiB4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmD4Lir4Lih4LmI4Lir4Lij4Li34Lit4Lil4Lit4LiH4LmD4LiK4LmJ4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4Lit4Li34LmI4LiZIiwiV2UgY291bGRuJ3QgbG9hZCB0aGlzIGpvYiI6IuC5gOC4o+C4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o+C4luC5geC4quC4lOC4h+C4leC4s+C5geC4q+C4meC5iOC4h+C4h+C4suC4meC4meC4teC5ieC5hOC4lOC5iSIsIldlIGNhbid0IGxvYWQgdGhpcyByaWdodCBub3cgYnV0IHdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCBpdC4iOiLguYDguKPguLLguYTguKHguYjguKrguLLguKHguLLguKPguJbguYHguKrguJTguIfguKvguJnguYnguLLguJnguLXguYnguYTguJTguYkg4LiC4LiT4Liw4LiZ4Li14LmJ4Lit4Lii4Li54LmI4LmD4LiZ4Lij4Liw4Lir4Lin4LmI4Liy4LiH4LiU4Liz4LmA4LiZ4Li04LiZ4LiB4Liy4Lij4LmB4LiB4LmJ4LmE4LiCIiwiVHJ5IHJlbG9hZGluZyBvciBzZWxlY3QgYW5vdGhlciBqb2IuIjoi4LmC4Lir4Lil4LiU4LmD4Lir4Lih4LmI4Lir4Lij4Li34Lit4LmA4Lil4Li34Lit4LiB4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4Lit4Li34LmI4LiZIiwiUmVsb2FkIjoi4Lil4Lit4LiH4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiHIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiW+G5ruG4qcOsw6zDrMWhIMS1w7bDtsO2w58gw6zDrMOsxaEg4LiB4Li14LmJw7bDtsO2IMaaw7bDtsO24LiB4Li14LmJxKPhur3hur3hur3FmSDEg8SDxIPhub3Eg8SDxIPDrMOsw6zGmsSDxIPEg8Ofxprhur3hur3hur1dIiwiU2VsZWN0IGFub3RoZXIgam9iIG9yIHRyeSBhIGRpZmZlcmVudCBzZWFyY2guIjoiW8Wg4bq94bq94bq9xprhur3hur3hur3Dp+G5ryDEg8SDxIPguIHguLXguYnDtsO2w7bhua/huKnhur3hur3hur3FmSDEtcO2w7bDtsOfIMO2w7bDtsWZIOG5r8WZw73DvcO9IMSDxIPEgyDGjMOsw6zDrMaSxpLhur3hur3hur3FmeG6veG6veG6veC4geC4teC5ieG5ryDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkuXSIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJbxbThur3hur3hur0gw6fDtsO2w7bHmseax5rGmsaM4LiB4Li14LmJJ+G5ryDGmsO2w7bDtsSDxIPEg8aMIOG5r+G4qcOsw6zDrMWhIMS1w7bDtsO2w59dIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IlvFtOG6veG6veG6vSDDp8SDxIPEg+C4geC4teC5iSfhua8gxprDtsO2w7bEg8SDxIPGjCDhua/huKnDrMOsw6zFoSDFmcOsw6zDrMSj4bip4bmvIOC4geC4teC5icO2w7bDtsW1IMOfx5rHmsea4bmvIMW14bq94bq94bq9J8WZ4bq94bq94bq9IMaMw7bDtsO2w6zDrMOs4LiB4Li14LmJxKMgw7bDtsO2x5rHmseaxZkgw5/hur3hur3hur3FoeG5ryDhua/DtsO2w7YgxpLDrMOsw6zqrZUgw6zDrMOs4bmvLl0iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJb4bmuxZnDvcO9w70gxZnhur3hur3hur3GmsO2w7bDtsSDxIPEg8aMw6zDrMOs4LiB4Li14LmJxKMgw7bDtsO2xZkgxaHhur3hur3hur3GmuG6veG6veG6vcOn4bmvIMSDxIPEg+C4geC4teC5icO2w7bDtuG5r+G4qeG6veG6veG6vcWZIMS1w7bDtsO2w58uXSIsIlJlbG9hZCI6IlvFmOG6veG6vcaaw7bDtsSDxIPGjF0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGlzIGpvYiBpcyBubyBsb25nZXIgYXZhaWxhYmxlIjoiW+G5ruG4qcOsw6zDrMWhIMS1w7bDtsO2w58gw6zDrMOsxaEg4LiB4Li14LmJw7bDtsO2IMaaw7bDtsO24LiB4Li14LmJxKPhur3hur3hur3FmSDEg8SDxIPhub3Eg8SDxIPDrMOsw6zGmsSDxIPEg8Ofxprhur3hur3hur1dIiwiU2VsZWN0IGFub3RoZXIgam9iIG9yIHRyeSBhIGRpZmZlcmVudCBzZWFyY2guIjoiW8Wg4bq94bq94bq9xprhur3hur3hur3Dp+G5ryDEg8SDxIPguIHguLXguYnDtsO2w7bhua/huKnhur3hur3hur3FmSDEtcO2w7bDtsOfIMO2w7bDtsWZIOG5r8WZw73DvcO9IMSDxIPEgyDGjMOsw6zDrMaSxpLhur3hur3hur3FmeG6veG6veG6veC4geC4teC5ieG5ryDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkuXSIsIldlIGNvdWxkbid0IGxvYWQgdGhpcyBqb2IiOiJbxbThur3hur3hur0gw6fDtsO2w7bHmseax5rGmsaM4LiB4Li14LmJJ+G5ryDGmsO2w7bDtsSDxIPEg8aMIOG5r+G4qcOsw6zDrMWhIMS1w7bDtsO2w59dIiwiV2UgY2FuJ3QgbG9hZCB0aGlzIHJpZ2h0IG5vdyBidXQgd2UncmUgZG9pbmcgb3VyIGJlc3QgdG8gZml4IGl0LiI6IlvFtOG6veG6veG6vSDDp8SDxIPEg+C4geC4teC5iSfhua8gxprDtsO2w7bEg8SDxIPGjCDhua/huKnDrMOsw6zFoSDFmcOsw6zDrMSj4bip4bmvIOC4geC4teC5icO2w7bDtsW1IMOfx5rHmsea4bmvIMW14bq94bq94bq9J8WZ4bq94bq94bq9IMaMw7bDtsO2w6zDrMOs4LiB4Li14LmJxKMgw7bDtsO2x5rHmseaxZkgw5/hur3hur3hur3FoeG5ryDhua/DtsO2w7YgxpLDrMOsw6zqrZUgw6zDrMOs4bmvLl0iLCJUcnkgcmVsb2FkaW5nIG9yIHNlbGVjdCBhbm90aGVyIGpvYi4iOiJb4bmuxZnDvcO9w70gxZnhur3hur3hur3GmsO2w7bDtsSDxIPEg8aMw6zDrMOs4LiB4Li14LmJxKMgw7bDtsO2xZkgxaHhur3hur3hur3GmuG6veG6veG6vcOn4bmvIMSDxIPEg+C4geC4teC5icO2w7bDtuG5r+G4qeG6veG6veG6vcWZIMS1w7bDtsO2w58uXSIsIlJlbG9hZCI6IlvFmOG6veG6vcaaw7bDtsSDxIPGjF0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    