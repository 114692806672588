import type { SearchParams } from '@seek/chalice-types';

import { getRefineSearchQuery } from 'src/components/Search/utils/utils.ts';
import { useJobCardEnrichedLocation } from 'src/modules/enriched-location';
import { useQualifiedLinkParams } from 'src/modules/qualified-location';
import { selectFeatureFlag } from 'src/store/featureFlags/featureFlags.ts';
import { useSelector } from 'src/store/react';
import { selectNormalisedCompanyName } from 'src/store/results/results';
import { selectSearchQuery } from 'src/store/search/search.ts';
import type { JobViewModelItemPartial } from 'src/types/JobViewModel.ts';

import { useLinkProps } from '../../../../NavLink/NavLink.tsx';

export function useLocationLinks({
  isJobExternal,
  unifiedLocation,
}: {
  isJobExternal: boolean;
  unifiedLocation?: JobViewModelItemPartial[] | null;
}) {
  const normalisedCompanyName = useSelector(selectNormalisedCompanyName);

  const searchParams = getRefineSearchQuery(useSelector(selectSearchQuery));
  const workArrangements = searchParams?.workarrangement || '';
  const isRemoteFilterEnabled = useSelector(
    selectFeatureFlag('remoteSearchFilter'),
  );

  const rel =
    isJobExternal || (isRemoteFilterEnabled && workArrangements)
      ? 'nofollow'
      : undefined;

  const getQualifiedLinkParams = useQualifiedLinkParams({
    rel,
    shouldUseUrlResolver: true,
  });
  const getJobCardEnrichedLocation = useJobCardEnrichedLocation();
  const getLinkProps = useLinkProps({
    getQualifiedLinkParams,
    rel,
  });

  return unifiedLocation?.map((item) => {
    const { params: query, name, title } = item;
    const normalisedQuery: SearchParams = normalisedCompanyName
      ? {
          ...query,
          keywords: normalisedCompanyName,
        }
      : query;

    const linkProps = getLinkProps(
      getJobCardEnrichedLocation({
        pathname: '/jobs',
        query: normalisedQuery,
      }),
    );

    return {
      name,
      title,
      ...linkProps,
    };
  });
}
