import type { SortMode } from '@seek/chalice-types';
import { useTranslations } from '@vocab/react';
import {
  memo,
  useCallback,
  useMemo,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';

import { getRefineSearchQuery } from 'src/components/Search/utils/utils';
import { sortModeViewModel } from 'src/modules/refine-job-search';
import { useSelector } from 'src/store/react';
import { selectResultsSortMode, selectSearchQuery } from 'src/store/selectors';

import { useAnalyticsFacade } from '../../../../modules/AnalyticsFacade';
import LinkSelect from '../LinkSelect/LinkSelect';

import translations from './.vocab';

const useSortModeOptions = () => {
  const searchQuery = getRefineSearchQuery(useSelector(selectSearchQuery));
  const sortMode = useSelector(selectResultsSortMode);
  const sortModeOptions = useMemo(
    () => sortModeViewModel({ searchQuery, sortMode }),
    [searchQuery, sortMode],
  );

  return sortModeOptions;
};

const SortedBy = ({
  setPageTriggerLoadClicked,
}: {
  setPageTriggerLoadClicked: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslations(translations);
  const analyticsFacade = useAnalyticsFacade();
  const initialSortModeOptions = useSortModeOptions();

  const [sortModeOptions, setSortModeOptions] = useState(
    initialSortModeOptions,
  );

  const onSortModeChange = useCallback(
    (value: SortMode) => {
      analyticsFacade.sortModeChanged(value);
      setPageTriggerLoadClicked(true);

      const updatedOptions = sortModeOptions.map((option) => ({
        ...option,
        isActive: option.value === value,
      }));
      setSortModeOptions(updatedOptions);
    },
    [analyticsFacade, setPageTriggerLoadClicked, sortModeOptions],
  );

  if (sortModeOptions === undefined) {
    return <></>;
  }

  return (
    <LinkSelect
      menuAlignment="right"
      elementType="sortby"
      label={t('Sorted by')}
      navigationLabel={t('Sort By')}
      options={sortModeOptions}
      onChange={(sortedByValue: string) => {
        onSortModeChange(
          sortedByValue === 'ListedDate' ? 'ListedDate' : 'KeywordRelevance',
        );
      }}
    />
  );
};

export default memo(SortedBy);
