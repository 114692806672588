import { Box, Column, Columns } from 'braid-design-system';
import { useEffect, useRef, type Dispatch, type SetStateAction } from 'react';

import { useSplitViewContext } from 'src/handlers/SplitViewContext';
import useAuthenticated from 'src/hooks/useAuthenticated';
import { useSelector } from 'src/store/react';
import { selectPaginationTotalPages } from 'src/store/selectors';

import Pagination from '../../Pagination/Pagination';
import RelatedSearches from '../../RelatedSearches/RelatedSearches';
import SaveSearchSection from '../../SaveSearchSection/SaveSearchSection';
import { SearchResultsHeaderSpacer } from '../../SearchResultHeaders/SearchResultsHeaders';
import useSeoFooter from '../../SeoFooter/useSeoFooter';
import { MRECBanner } from '../MRECBanner/MRECBanner';

import * as styles from './SplitViewLayout.css';

const outerSpacing = ['none', 'none', 'xxlarge'] as const;
const listFooterItemSpacing = 'xlarge';

const SplitViewLayout = ({
  ListView,
  DetailView,
  setPageTriggerLoadClicked,
}: {
  ListView: React.ReactNode;
  DetailView: React.ReactNode;
  setPageTriggerLoadClicked: Dispatch<SetStateAction<boolean>>;
}) => {
  const { selectedJob, isLoading } = useSplitViewContext();
  const shouldShowInitState = !Boolean(selectedJob);

  const { relatedList, shouldRenderRelatedSearch } = useSeoFooter();

  const totalPages = useSelector(selectPaginationTotalPages);
  const shouldHavePagination = totalPages > 1;
  const jobDetailsPaneElement = useRef<HTMLDivElement>(null);

  const authenticateState = useAuthenticated();
  const shouldShowSaveSearchAbovePagination =
    authenticateState === 'authenticated';
  const shouldShowSaveSearchUnderPagination =
    authenticateState === 'unauthenticated';

  useEffect(() => {
    if (jobDetailsPaneElement.current?.scrollTo)
      jobDetailsPaneElement.current.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
  }, [DetailView]);

  return (
    <Box
      width="full"
      data-automation="splitViewParentWrapper"
      className={styles.splitViewWrapper}
    >
      <Columns collapseBelow="desktop" space={outerSpacing}>
        <Column width="2/5">
          <Box
            position="relative"
            display="flex"
            flexDirection="column"
            paddingBottom={outerSpacing}
          >
            {shouldRenderRelatedSearch && (
              <Box
                component="section"
                paddingTop={listFooterItemSpacing}
                data-automation="related-searches-splitview"
                className={styles.relatedSearches}
              >
                <RelatedSearches filterInfo={relatedList} />
              </Box>
            )}
            <Box className={shouldHavePagination && styles.jobListWrapper}>
              {ListView}
            </Box>
            {shouldShowSaveSearchAbovePagination && (
              <SaveSearchSection spaceTop={listFooterItemSpacing} />
            )}
            {shouldHavePagination && (
              <Box paddingTop={listFooterItemSpacing}>
                <Pagination
                  setPageTriggerLoadClicked={setPageTriggerLoadClicked}
                />
              </Box>
            )}
            {shouldShowSaveSearchUnderPagination && (
              <SaveSearchSection spaceTop={listFooterItemSpacing} />
            )}
            <MRECBanner />
          </Box>
        </Column>
        <Column>
          <Box
            background={shouldShowInitState ? undefined : 'surface'}
            height="full"
            display={['none', 'none', 'block']}
            className={styles.jobDetailsWrapper}
          >
            <SearchResultsHeaderSpacer />
            <Box
              data-automation="splitViewJobDetailsWrapper"
              height="full"
              background="surface"
              marginBottom={shouldShowInitState ? outerSpacing : 'none'}
              className={[
                styles.jobDetailsIndependentScrollSession,
                {
                  [styles.makeScrollable]: !isLoading,
                  [styles.disableScroll]: isLoading,
                },
              ]}
              ref={jobDetailsPaneElement}
              paddingBottom={outerSpacing}
            >
              {DetailView}
            </Box>
          </Box>
        </Column>
      </Columns>
    </Box>
  );
};
export default SplitViewLayout;
