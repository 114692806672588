import type { SearchResultJob } from '@seek/chalice-types';
import { Box } from 'braid-design-system';
import {
  useEffect,
  type ComponentProps,
  type ReactNode,
  type Dispatch,
  type SetStateAction,
} from 'react';

import { AccessabilityLinks } from 'src/components/SearchResultPage/SplitView/AccessabilityLinks/AccessabilityLinks';

import { useSplitViewContext } from '../../../handlers/SplitViewContext';
import SearchResultList from '../SearchResultList/SearchResultList';

import JobDetail from './JobDetail/JobDetail';
import SplitViewLayout from './SplitViewLayout/SplitViewLayout';
import useSplitViewOpen1stJobAdEffect from './useSplitViewOpen1stJobAdEffect/useSplitViewOpen1stJobAdEffect';

interface SplitViewProps {
  jobs: SearchResultJob[];
  resultSummary: ReactNode;
  isCompanySearch: ComponentProps<typeof SearchResultList>['isCompanySearch'];
  setPageTriggerLoadClicked: Dispatch<SetStateAction<boolean>>;
}

const SplitView = ({
  jobs,
  resultSummary,
  isCompanySearch,
  setPageTriggerLoadClicked,
}: SplitViewProps) => {
  const {
    selectedJob,
    setSelectedJob,
    currentJobDetails,
    fetchJobDetails,
    isLoading,
  } = useSplitViewContext();

  useSplitViewOpen1stJobAdEffect(jobs);

  useEffect(() => {
    window.hj =
      window.hj ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        (hj.q = hj.q || []).push(arguments);
      };

    window.hj('event', 'srp_splitview_experiment');
  }, [jobs]);

  return (
    <SplitViewLayout
      ListView={
        <Box>
          {resultSummary}
          {jobs.length > 0 && (
            <SearchResultList
              jobs={jobs}
              isCompanySearch={isCompanySearch}
              setCurrentJob={setSelectedJob}
              selectedJobId={selectedJob?.id}
            />
          )}
        </Box>
      }
      DetailView={
        <>
          <AccessabilityLinks placement="job-details" position="top" />
          <JobDetail
            currentJobDetails={selectedJob ? currentJobDetails : null}
            fetchJobDetails={fetchJobDetails}
            isLoading={isLoading}
          />
          {selectedJob ? (
            <AccessabilityLinks placement="job-details" position="bottom" />
          ) : null}
        </>
      }
      setPageTriggerLoadClicked={setPageTriggerLoadClicked}
    />
  );
};

export default SplitView;
