import { useTranslations } from '@vocab/react';
import type { Dispatch, SetStateAction } from 'react';

import { Pagination as BraidPagination } from 'src/components/NavLink/NavLink';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useSelector } from 'src/store/react';
import {
  selectLocationPageNumber,
  selectPaginationParameters,
  selectPaginationTotalPages,
  selectSearchQuery,
} from 'src/store/selectors';

import translations from './.vocab';

import * as styles from './Pagination.css';

const Pagination = ({
  setPageTriggerLoadClicked,
}: {
  setPageTriggerLoadClicked: Dispatch<SetStateAction<boolean>>;
}) => {
  const analyticsFacade = useAnalyticsFacade();
  const currentPageNumber = useSelector(selectLocationPageNumber);
  const paginationParameters = useSelector(selectPaginationParameters);
  const query = useSelector(selectSearchQuery);
  const totalPages = useSelector(selectPaginationTotalPages);

  const { t } = useTranslations(translations);

  return totalPages > 1 ? (
    <div className={styles.paginationContainer}>
      <BraidPagination
        page={currentPageNumber}
        total={totalPages}
        pageLimit={3}
        linkProps={({ page }) => ({
          'data-automation': `page-${page}`,
          location: {
            pathname: '/jobs',
            state: { paginationParameters } as any,
            query: { ...query, ...{ page: page.toString() } },
          },
          preNavigationHooks: [
            () => {
              analyticsFacade.paginationPressed();
              setPageTriggerLoadClicked(true);
            },
          ],
        })}
        label={t('Pagination of results')}
        previousLabel={t('Prev')}
        nextLabel={t('Next')}
      />
    </div>
  ) : null;
};

export default Pagination;
