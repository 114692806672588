import { Inline, Text, TextLink } from 'braid-design-system';
import { useMemo } from 'react';

import type { BannerItems } from 'src/graphql/graphql';

import useSeoFooter from '../useSeoFooter';

const ITEMS_DISPLAY_LIMIT = 6;

interface FooterLmisProps {
  shouldDisplayAll: boolean;
  items?: BannerItems[];
}

export const FooterLmis = ({ shouldDisplayAll, items }: FooterLmisProps) => {
  const itemsToRender = useMemo(
    () => (shouldDisplayAll ? items : items?.slice(0, ITEMS_DISPLAY_LIMIT)),
    [items, shouldDisplayAll],
  );
  const { tabIndex } = useSeoFooter();

  return (
    <Inline component="ul" space="small">
      {itemsToRender?.map(({ id, text, href }) => (
        <Text size="small" key={id}>
          <TextLink
            href={href || ''}
            data-automation="course-link"
            tabIndex={tabIndex}
            weight="weak"
            aria-label={text || ''}
          >
            {text}
          </TextLink>
        </Text>
      ))}
    </Inline>
  );
};
