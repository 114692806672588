import { useResponsiveValue } from 'braid-design-system';
import { useEffect, useState, type ComponentProps } from 'react';

import { useSplitViewContext } from 'src/handlers/SplitViewContext';
import type { HubbleJDVEventTriggerTag } from 'src/hooks/trackJobDetailsLoaded';
import { AUTO_SELECT_SPLIT_VIEW_FIRST_JOB } from 'src/store/featureFlags/featureFlags';
import { useSelector } from 'src/store/react';
import { selectSelectedJobId } from 'src/store/results/results';
import { selectFeatureFlag } from 'src/store/selectors';

import type SplitView from '../SplitView';

type ArrayElement<T> = T extends Array<infer U> ? U : never;

const getFirstJobToSelect = (
  jobList: ComponentProps<typeof SplitView>['jobs'],
  selectedJobIdFromQS: ReturnType<typeof selectSelectedJobId>,
  autoSelectSplitViewFirstJob: boolean,
): {
  job: ArrayElement<typeof jobList> | undefined;
  eventTrigger?: HubbleJDVEventTriggerTag;
} => {
  if (selectedJobIdFromQS) {
    const selectedJobIdFromQSInNumber = Number(selectedJobIdFromQS);
    const foundJob = jobList.find(
      ({ id }) => id === selectedJobIdFromQSInNumber,
    );
    if (foundJob) {
      return { job: foundJob, eventTrigger: 'pre_selected' };
    }
  }

  return autoSelectSplitViewFirstJob
    ? { job: jobList[0], eventTrigger: 'auto_display' }
    : { job: undefined };
};

const useSplitViewOpen1stJobAdEffect = (
  jobs: ComponentProps<typeof SplitView>['jobs'],
) => {
  const { selectedJob, setSelectedJob } = useSplitViewContext();

  const selectedJobIdFromQueryString = useSelector(selectSelectedJobId);
  const autoSelectSplitViewFirstJob = useSelector(
    selectFeatureFlag(AUTO_SELECT_SPLIT_VIEW_FIRST_JOB),
  );

  const [isJobsReloaded, setIsJobsReloaded] = useState(false);
  const isTablet = useResponsiveValue()({
    mobile: true,
    desktop: false,
  });

  useEffect(() => {
    if (jobs.length > 0) {
      setIsJobsReloaded(true);
    }
  }, [jobs]);

  // This useEffect will make sure the Job Detail API will not be called on for screen sizes below desktop by default
  useEffect(() => {
    const isServerSideRender = isTablet === null;
    if (!isServerSideRender) {
      if (isTablet === false) {
        // 1. when `isJobsReloaded` is true, means the page changed, and should always pick the first job to show
        // 2. when `selectedJob === undefined`, means the app changed the screen size and did not select any job previously, so we should pick the first job to show
        if (isJobsReloaded || selectedJob === undefined) {
          const { eventTrigger, job } = getFirstJobToSelect(
            jobs,
            selectedJobIdFromQueryString,
            autoSelectSplitViewFirstJob,
          );
          setSelectedJob(job, eventTrigger);
          setIsJobsReloaded(false);
        }
      }
      // For tablet and below screen sizes, and when page changed, should clear all selected jobs
      else if (isTablet && isJobsReloaded) {
        setSelectedJob(undefined);
        setIsJobsReloaded(false);
      }
    }
  }, [
    isJobsReloaded,
    isTablet,
    jobs,
    selectedJob,
    selectedJobIdFromQueryString,
    setSelectedJob,
    autoSelectSplitViewFirstJob,
  ]);
};

export default useSplitViewOpen1stJobAdEffect;
