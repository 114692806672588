import { useMutation } from '@apollo/client';
import { useTranslations } from '@vocab/react';
import { useDispatch } from 'react-redux';

import { CREATE_REPORTED_JOB } from 'src/modules/graphql/mutations/reportJob';
import type {
  CreateReportedJob,
  CreateReportedJobVariables,
} from 'src/modules/graphql/mutations/types/CreateReportedJob';
import { logger } from 'src/modules/logger';
import {
  JOB_FRAUD_REPORT_SUCCESS,
  JOB_FRAUD_REPORT_FAILURE,
} from 'src/store/jobdetails/jobdetails';
import type { ReportJobInput } from 'src/types/globalTypes';

import translations from './.vocab';

const useCreateEvent = () => {
  const { t } = useTranslations(translations);
  const actionCreator = (success: boolean) => {
    const [type, msg] = success
      ? [JOB_FRAUD_REPORT_SUCCESS, t('Fraud Report Success')]
      : [JOB_FRAUD_REPORT_FAILURE, t('Fraud Report Failure')];

    return {
      type,
      meta: {
        metrics: {
          name: type,
        },
        hotjar: msg,
      },
    };
  };
  return actionCreator;
};

export const useCreateReportedJob = () => {
  const dispatch = useDispatch();
  const actionCreator = useCreateEvent();

  const [reportJob] = useMutation<
    CreateReportedJob,
    CreateReportedJobVariables
  >(CREATE_REPORTED_JOB);

  return async (report: ReportJobInput) => {
    try {
      const result = await reportJob({ variables: { report } });
      const success = result?.data?.reportJob?.success || false;
      dispatch(actionCreator(success));
      return success;
    } catch (err) {
      logger.error({ err }, 'Error calling reportJobAd');
      dispatch(actionCreator(false));
      return false;
    }
  };
};
