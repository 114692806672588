import type { SearchParams } from '@seek/chalice-types';
import type { JobCardLinkProps } from '@seek/job-card';

import { getRefineSearchQuery } from 'src/components/Search/utils/utils.ts';
import { useJobCardEnrichedLocation } from 'src/modules/enriched-location';
import { useQualifiedLinkParams } from 'src/modules/qualified-location';
import { selectFeatureFlag } from 'src/store/featureFlags/featureFlags.ts';
import { useSelector } from 'src/store/react';
import { selectNormalisedCompanyName } from 'src/store/results/results';
import { selectSearchQuery } from 'src/store/search/search.ts';
import type { JobViewModelClassification } from 'src/types/JobViewModel.ts';

import { useLinkProps } from '../../../../NavLink/NavLink.tsx';

export function useClassificationLinks({
  classification: jobClassification,
}: {
  classification: JobViewModelClassification;
}) {
  const normalisedCompanyName = useSelector(selectNormalisedCompanyName);

  const searchParams = getRefineSearchQuery(useSelector(selectSearchQuery));
  const workArrangements = searchParams?.workarrangement || '';
  const isRemoteFilterEnabled = useSelector(
    selectFeatureFlag('remoteSearchFilter'),
  );
  const rel =
    isRemoteFilterEnabled && workArrangements ? 'nofollow' : undefined;

  const getQualifiedLinkParams = useQualifiedLinkParams({
    rel,
    shouldUseUrlResolver: true,
  });
  const getJobCardEnrichedLocation = useJobCardEnrichedLocation();
  const getLinkProps = useLinkProps({
    getQualifiedLinkParams,
  });

  const { classification, subClassification } = jobClassification;
  return [classification, subClassification].reduce<JobCardLinkProps[]>(
    (acc, classificationItem) => {
      if (!classificationItem) {
        return acc;
      }

      const { name, title, params: query } = classificationItem;

      const normalisedQuery: SearchParams = normalisedCompanyName
        ? {
            ...query,
            keywords: normalisedCompanyName,
          }
        : query;

      const linkProps = getLinkProps(
        getJobCardEnrichedLocation({
          pathname: '/jobs',
          query: normalisedQuery,
        }),
      );

      acc.push({
        name,
        title,
        ...linkProps,
      });

      return acc;
    },
    [],
  );
}
