import { useEffect, useState } from 'react';

import { Placeholder } from 'src/components/Placeholder/Placeholder';
import { advertsIsEnabled } from 'src/config';
import { GooglePublisherTag } from 'src/modules/gpt-react';

import useHoustonAd from './useHoustonAd';

interface Props {
  height: number;
  minWidth: number;
  tagId: string;
  width: number;
}

const HoustonAd = ({ minWidth, tagId, width, height }: Props) => {
  const { targetingData, shouldRenderAdverts } = useHoustonAd({
    minimumClientWidth: minWidth,
  });

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  // This experiment tag is sent only when we display the tower ad unit in split view
  const targetingDataArrayForSplitView = [
    ...targetingData,
    { key: 'experiment', value: 'splitViewAd' },
  ];
  if (shouldRenderAdverts && isClient) {
    return advertsIsEnabled ? (
      <GooglePublisherTag
        tagId={tagId}
        width={width}
        height={height}
        targetingData={targetingDataArrayForSplitView}
      />
    ) : (
      <Placeholder
        width={width}
        height={height}
        label={`Advertisement with Tag ID: ${tagId}`}
        data-automation={`ad-${tagId.split('/').pop()}`}
      />
    );
  }

  return null;
};

export default HoustonAd;
