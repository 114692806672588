import type { SearchResultJob } from '@seek/chalice-types';
import { useTranslations } from '@vocab/react';

import { useJobCardEnrichedLocation } from 'src/modules/enriched-location';
import { useQualifiedLinkParams } from 'src/modules/qualified-location';

import { useLinkProps } from '../../../../NavLink/NavLink.tsx';
import translations from '../../.vocab';

type PartialSearchResultJob = Pick<SearchResultJob, 'companyName'> & {
  advertiser: Pick<SearchResultJob['advertiser'], 'id' | 'description'>;
  isPrivateAdvertiser?: boolean;
};
export function useCompanyLink({ job }: { job: PartialSearchResultJob }) {
  const { t } = useTranslations(translations);
  const advertiserDescription = job.advertiser.description || '';

  const getQualifiedLinkParams = useQualifiedLinkParams({
    shouldUseUrlResolver: true,
  });
  const getJobCardEnrichedLocation = useJobCardEnrichedLocation();
  const getLinkProps = useLinkProps({
    getQualifiedLinkParams,
  });

  if (job.isPrivateAdvertiser) {
    return {
      name: advertiserDescription,
    };
  }

  const linkProps = getLinkProps(
    getJobCardEnrichedLocation({
      pathname: '/jobs',
      query: job.companyName
        ? { keywords: job.companyName }
        : { advertiserid: job.advertiser.id },
    }),
  );

  return {
    name: advertiserDescription,
    title: t('Jobs at {companyName}', {
      companyName: job.companyName ?? advertiserDescription,
    }),
    ...linkProps,
  };
}
