import { ButtonLink, IconFilter, IconSearch } from 'braid-design-system';
import { useCallback } from 'react';

import LeftAlignedButtonLabel from 'src/components/LeftAlignedButtonLabel/LeftAlignedButtonLabel';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';

import useSeoFooter from '../SeoFooter/useSeoFooter';

export interface RelatedSearchItemProps {
  text: string;
  href: string;
  position: number;
  rel?: string;
  type: 'related' | 'facets';
}

type OnLinkClick = (text: RelatedSearchItemProps['text']) => void;

const RelatedSearchItem = ({
  text,
  href,
  position,
  rel,
  type,
}: RelatedSearchItemProps) => {
  const analyticsFacade = useAnalyticsFacade();
  const { tabIndex } = useSeoFooter();

  const onLinkClick = useCallback<OnLinkClick>(
    (linkText) => {
      analyticsFacade.relatedSearchPressed({
        linkContext: {
          linkText,
          linkPosition: String(position),
        },
      });
    },
    [analyticsFacade, position],
  );

  return (
    <ButtonLink
      variant="soft"
      size="small"
      tone="neutral"
      onClick={() => onLinkClick(text)}
      href={href}
      rel={rel}
      data={{ automation: `related-search-link-${position}` }}
      tabIndex={tabIndex}
    >
      <LeftAlignedButtonLabel
        label={text}
        icon={type === 'related' ? <IconSearch /> : <IconFilter />}
      />
    </ButtonLink>
  );
};

export default RelatedSearchItem;
