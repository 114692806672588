import useFetchSavedSearches from 'src/modules/hooks/SavedSearch';
import { useSelector } from 'src/store/react';
import { SavedSearchStatus } from 'src/store/saveSearch/saveSearch';
import {
  selectSaveSearchStatus,
  selectSaveSearchErrorMessage,
  selectSaveable,
} from 'src/store/selectors';

const useCommonProps = () => {
  const status = useSelector(selectSaveSearchStatus);
  const errorMessage = useSelector(selectSaveSearchErrorMessage);
  const isSaved = status === SavedSearchStatus.SAVED;
  const loading = status === SavedSearchStatus.SAVING;
  const savable = useSelector(selectSaveable);

  return {
    status,
    savable,
    isSaved,
    loading,
    errorMessage,
  };
};

export const useSaveSearchSectionLoggedIn = () => {
  const { isSaved, loading, status, savable, errorMessage } = useCommonProps();
  const { isSearchSaved } = useFetchSavedSearches();

  return {
    isSaved,
    isSearchSaved,
    loading,
    status,
    errorMessage,
    savable,
  };
};

export const useSaveSearchSectionLoggedOut = () => {
  const { isSaved, loading, savable } = useCommonProps();

  return { isSaved, loading, savable };
};
