
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2pvYn0gb25lIHtqb2J9IG90aGVyIHtqb2JzfX17YXRDb21wYW55fSIsImF0IHtjb21wYW55fSI6ImF0IHtjb21wYW55fSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2xvd29uZ2FufSBvbmUge2xvd29uZ2FufSBvdGhlciB7bG93b25nYW59fXthdENvbXBhbnl9IiwiYXQge2NvbXBhbnl9IjoiZGkge2NvbXBhbnl9In0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge2xvd29uZ2FufSBvbmUge2xvd29uZ2FufSBvdGhlciB7bG93b25nYW59fXthdENvbXBhbnl9IiwiYXQge2NvbXBhbnl9IjoiZGkge2NvbXBhbnl9In0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge+C4h+C4suC4mX0gb25lIHvguIfguLLguJl9IG90aGVyIHvguIfguLLguJl9fXthdENvbXBhbnl9IiwiYXQge2NvbXBhbnl9Ijoi4LiX4Li14LmIIHtjb21wYW55fSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiPHNwYW4+e2Rpc3BsYXlUb3RhbENvdW50fTwvc3Bhbj4ge3RvdGFsQ291bnQsIHBsdXJhbCwgPTAge+C4h+C4suC4mX0gb25lIHvguIfguLLguJl9IG90aGVyIHvguIfguLLguJl9fXthdENvbXBhbnl9IiwiYXQge2NvbXBhbnl9Ijoi4LiX4Li14LmIIHtjb21wYW55fSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiWzxzcGFuPntkaXNwbGF5VG90YWxDb3VudH08L3NwYW4+IHt0b3RhbENvdW50LHBsdXJhbCw9MHvEtcO2w7bDtsOffSBvbmV7xLXDtsO2w7bDn30gb3RoZXJ7xLXDtsO2w7bDn8WhfX17YXRDb21wYW55fV0iLCJhdCB7Y29tcGFueX0iOiJbxIPEg8SD4bmvIHtjb21wYW55fV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ0b3RhbCBqb2JzIGNvdW50IGZvdW5kIjoiWzxzcGFuPntkaXNwbGF5VG90YWxDb3VudH08L3NwYW4+IHt0b3RhbENvdW50LHBsdXJhbCw9MHvEtcO2w7bDtsOffSBvbmV7xLXDtsO2w7bDn30gb3RoZXJ7xLXDtsO2w7bDn8WhfX17YXRDb21wYW55fV0iLCJhdCB7Y29tcGFueX0iOiJbxIPEg8SD4bmvIHtjb21wYW55fV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    