import type { SearchParams, SearchResultJob } from '@seek/chalice-types';
import { useTranslations } from '@vocab/react';
import { Box, IconCompany, Text } from 'braid-design-system';
import compact from 'lodash/compact';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';

import { TextLink } from 'src/components/NavLink/NavLink';

import translations from './.vocab';

interface Props {
  clearCompanyRefinements: SearchParams;
  jobs: SearchResultJob[];
  isCompanySearch: boolean;
}

const CompanyResults = ({
  jobs,
  clearCompanyRefinements,
  isCompanySearch,
}: Props) => {
  const { t } = useTranslations(translations);
  const uniqCompanyNames: string[] = uniq(
    compact(flatMap(jobs, 'advertiser.description')),
  );
  const companyNameCount: number = uniqCompanyNames.length;
  const isSingleCompany: boolean = companyNameCount === 1;

  return (
    <Text
      size="small"
      icon={isCompanySearch ? undefined : <IconCompany />}
      data={{ automation: 'companyResults' }}
    >
      <Box component="span" data-automation="companyName">
        {isSingleCompany
          ? `${t('Showing jobs for {companyName}', {
              companyName: uniqCompanyNames?.[0] || '',
            })}.`
          : t('Showing jobs for {companyNameCount} companies.', {
              companyNameCount: companyNameCount.toString(),
            })}
      </Box>{' '}
      <TextLink
        data-automation="companyResultsClicked"
        location={{ pathname: '/jobs', query: clearCompanyRefinements }}
        rel="nofollow"
      >
        {/* Note, that although the link says 'Show all Jobs', it's not meant
             to show all jobs. It's only meant to clear the company search.
             See discussion at https://github.com/SEEK-Jobs/chalice/issues/1568 */}
        {t('Show all Jobs')}
      </TextLink>
    </Text>
  );
};

export default CompanyResults;
