import { useTranslations } from '@vocab/react';
import type { useToast } from 'braid-design-system';
import { useMemo } from 'react';

import { savedSearchesPath } from 'src/config';
import { SavedSearchStatus } from 'src/store/saveSearch/saveSearch';
import { useLocalisedLinks } from 'src/utils/melwaysHelper';

import translations from './.vocab';

const { SAVED, EXCEEDED, ERROR, INVALID_LOCATION, DUPLICATE } =
  SavedSearchStatus;

const analyticsMessageMap: Partial<Record<SavedSearchStatus, string>> = {
  SAVED: 'We’ve saved your search',
  EXCEEDED: 'You’ve already saved 10 searches',
  DUPLICATE: 'A duplicate of this saved search is found.',
  INVALID_LOCATION:
    'To save a search, it must have a valid location. Type a new location and try again.',
  ERROR: 'There is something wrong on our end',
};

type Toast = Parameters<ReturnType<typeof useToast>>[0] & {
  isError: boolean;
  analyticsMessage: string;
};

export const useToastDetails = ({
  status,
  statusMessage,
}: {
  status: SavedSearchStatus;
  statusMessage: string;
}): Toast | undefined => {
  const { t } = useTranslations(translations);
  const analyticsMessage = analyticsMessageMap[status] ?? '';

  const localisedSavedSearchesPath = useLocalisedLinks({
    path: savedSearchesPath,
  });

  return useMemo(() => {
    const errorMessageDescription =
      status === ERROR
        ? {
            message: t('There is something wrong on our end'), // Is this needed? Doesn't `ERROR` already return an `errorMessage`?
            description: t('Try again in a little while'),
          }
        : {
            message: statusMessage,
          };
    switch (status) {
      case SAVED:
        return {
          isError: false,
          tone: 'positive',
          message: t('We’ve saved your search'),
          analyticsMessage,
        };
      case EXCEEDED:
      case DUPLICATE:
      case INVALID_LOCATION:
      case ERROR:
        return {
          isError: true,
          tone: 'critical',
          ...errorMessageDescription,
          action:
            status === EXCEEDED
              ? {
                  label: t('Manage your saved searches'),
                  onClick: () =>
                    window.open(localisedSavedSearchesPath, '_blank'),
                }
              : undefined,
          analyticsMessage,
        };
      default:
        return undefined;
    }
  }, [analyticsMessage, localisedSavedSearchesPath, status, statusMessage, t]);
};
