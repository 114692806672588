import { type ComponentProps, useMemo } from 'react';

import { jobViewModel } from 'src/modules/refine-job-search';
import { useSelector } from 'src/store/react';
import { selectResultsLocation, selectSearchQuery } from 'src/store/selectors';
import type { JobViewModel } from 'src/types/JobViewModel';

import type JobListItem from '../JobListItem';

const useJobViewModel = (job: ComponentProps<typeof JobListItem>['job']) => {
  const location = useSelector(selectResultsLocation);
  const query = useSelector(selectSearchQuery);
  const viewModel: JobViewModel = useMemo(
    () => jobViewModel({ job, query, location }),
    [job, query, location],
  );
  return viewModel;
};

export default useJobViewModel;
