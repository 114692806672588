import { getSolSearchDataProp } from '@seek/sol-js';
import { useTranslations } from '@vocab/react';
import { Box, Text } from 'braid-design-system';
import type {
  ComponentProps,
  Dispatch,
  ReactNode,
  SetStateAction,
} from 'react';

import { useShouldHideJobCount } from 'src/components/SearchResultPage/SearchResultSummary/ShouldHideJobCountDetector';
import { useSelector } from 'src/store/react';
import {
  selectCompanySuggestion,
  selectIsResultsInError,
  selectIsResultsLoading,
  selectResults,
  selectSolMetadataString,
} from 'src/store/selectors';

import translations from './.vocab';
import SortedBy from './SortedBy/SortedBy';

import * as styleRefs from './SearchResultSummary.css';

const TotalJobsMessageContainer = ({ children }: { children: ReactNode }) => {
  const commonProps: Pick<
    ComponentProps<typeof Box>,
    'id' | 'data' | 'component'
  > = {
    id: 'SearchSummary',
    data: { automation: 'totalJobsMessage' },
    component: 'h1',
  };

  return (
    <Text size="small" {...commonProps}>
      {children}
    </Text>
  );
};

const SearchResultSummary = ({
  setPageTriggerLoadClicked,
}: {
  setPageTriggerLoadClicked: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslations(translations);

  const searchResultsViewModel = useSelector(selectResults);
  const isLoading = useSelector(selectIsResultsLoading);
  const companySuggestion = useSelector(selectCompanySuggestion);
  const solMetadataString = useSelector(selectSolMetadataString) || '';
  const isError = useSelector(selectIsResultsInError);

  const { summary } = searchResultsViewModel || {};

  const hideJobCount = useShouldHideJobCount();

  const { keywords } = companySuggestion?.search || {};

  return !isError ? (
    <Box
      id="searchResultSummary"
      data-automation="searchResultSummary"
      {...getSolSearchDataProp(solMetadataString)}
    >
      {!isLoading ? (
        <Box
          id="aria-search-bar"
          aria-live="polite"
          aria-labelledby="SearchSummary"
          role="contentinfo"
          alignItems="center"
          display="flex"
          justifyContent={hideJobCount ? 'flexEnd' : 'spaceBetween'}
          flexWrap="wrap"
          className={styleRefs.container}
        >
          {!hideJobCount && (
            <TotalJobsMessageContainer>
              {t('total jobs count found', {
                displayTotalCount: summary?.displayTotalCount || '0',
                totalCount: Number(summary?.displayTotalCount || 0),
                atCompany: keywords
                  ? ` ${t('at {company}', { company: keywords })}`
                  : '',
                span: (children: ReactNode) => (
                  <span key={0} data-automation="totalJobsCount">
                    {children}
                  </span>
                ),
              })}
            </TotalJobsMessageContainer>
          )}
          <SortedBy setPageTriggerLoadClicked={setPageTriggerLoadClicked} />
        </Box>
      ) : null}
    </Box>
  ) : null;
};

export default SearchResultSummary;
