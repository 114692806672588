import { useTranslations } from '@vocab/react';
import {
  Box,
  Column,
  Columns,
  HiddenVisually,
  IconChevron,
  Inline,
  MenuItem,
  MenuRenderer,
  Strong,
  Text,
} from 'braid-design-system';
import filter from 'lodash/filter';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import type { ComponentProps, SyntheticEvent } from 'react';

import { MenuItemLink } from 'src/components/NavLink/NavLink';
import type { Option } from 'src/types/globals';

import translations from './.vocab';

import * as styles from './LinkSelect.css';

interface LinkSelectProps {
  elementType: string;
  label: string;
  icon?: ComponentProps<typeof Text>['icon'];
  navigationLabel: string;
  options: Option[];
  menuAlignment?: ComponentProps<typeof MenuRenderer>['align'];
  onChange?: (value: string) => void;
  postDropdownText?: string;
  openDropdownHandler?: () => void;
}

const LinkSelect = ({
  elementType,
  label,
  icon,
  menuAlignment = 'left',
  navigationLabel,
  options,
  onChange,
  postDropdownText,
  openDropdownHandler,
}: LinkSelectProps) => {
  let checkbox: any;

  const { t } = useTranslations(translations);

  const activeOption = filter(options, 'isActive')[0];
  const activeLabel = toLower(get(activeOption, 'label'));

  const inputId = `${elementType}-input`;
  const labelId = `${elementType}-label`;

  const storeCheckboxReference = (targetCheckbox: any): void => {
    if (targetCheckbox !== null) {
      checkbox = targetCheckbox;
    }
  };

  const toggleCheckbox = () => {
    checkbox.checked = !checkbox.checked;
  };

  const onMenuItemClick = (value: string) => {
    if (onChange) {
      onChange(value);
    }
    toggleCheckbox();
  };

  const toggleDropdownHandler = (event: SyntheticEvent) => {
    const optionsDropdown = document.querySelector(
      `#${event.currentTarget.id} ~ ul`,
    );

    const isNoVisibleDropdown = optionsDropdown
      ? window.getComputedStyle(optionsDropdown).opacity !== '0'
      : true;

    if (openDropdownHandler === undefined || isNoVisibleDropdown) {
      return;
    }

    openDropdownHandler();
  };

  const RenderSingleOption = () => (
    <Text icon={icon} size="small">
      {`${label} `}
      <Strong>{activeLabel}</Strong>
      {postDropdownText ? ` ${postDropdownText}` : null}
    </Text>
  );

  const useRenderMultiOptions = () => {
    if (!activeLabel) {
      return null;
    }

    const hasPostDropdownText = Boolean(postDropdownText);
    return options.length === 1 ? (
      <RenderSingleOption />
    ) : (
      <>
        <HiddenVisually>
          <Box
            component="input"
            aria-hidden="true"
            type="checkbox"
            id={inputId}
            ref={storeCheckboxReference}
            value=""
            onFocus={toggleDropdownHandler}
          />
        </HiddenVisually>
        <Columns space="xsmall">
          {icon ? (
            <Column width="content">
              <Text size="small">{icon}</Text>
            </Column>
          ) : null}
          <Column>
            <Inline align="left" space="xsmall" alignY="center">
              <MenuRenderer
                align={menuAlignment}
                offsetSpace="small"
                trigger={(triggerProps, { open }) => (
                  <>
                    <Box
                      userSelect="none"
                      cursor="pointer"
                      className={{
                        [styles.fixMenuSpacing]: hasPostDropdownText,
                      }}
                      {...triggerProps}
                    >
                      <Text
                        size="small"
                        data={{ automation: 'activeDistance' }}
                      >
                        {`${label} `}
                        <Strong>{`${activeLabel} `}</Strong>
                        <IconChevron
                          direction={open ? 'up' : 'down'}
                          alignY="lowercase"
                        />
                      </Text>
                    </Box>
                    <Box
                      component="label"
                      htmlFor={inputId}
                      className={styles.backdrop}
                    >
                      {t('Done')}
                    </Box>
                  </>
                )}
              >
                {options.map(
                  ({ value, label: optionLabel, refineParams }, index) => {
                    const location = {
                      pathname: '/jobs',
                      query: refineParams,
                    };
                    return refineParams ? (
                      <MenuItemLink
                        data={{ automation: `${elementType}-${index}` }}
                        key={value}
                        onClick={() => onMenuItemClick(value)}
                        aria-label={t('{navigationLabel} {label}', {
                          navigationLabel,
                          label,
                        })}
                        href={location}
                      >
                        {optionLabel}
                      </MenuItemLink>
                    ) : (
                      <MenuItem key={label}>{optionLabel}</MenuItem>
                    );
                  },
                )}
              </MenuRenderer>

              {hasPostDropdownText && (
                <Box component="label" htmlFor={inputId}>
                  <Text size="small">
                    {' '}
                    {postDropdownText ? postDropdownText : ''}
                  </Text>
                </Box>
              )}
            </Inline>
          </Column>
        </Columns>
      </>
    );
  };

  const OptionsToRender = () => useRenderMultiOptions();

  return (
    <Box
      aria-labelledby={labelId}
      role="contentinfo"
      id={elementType}
      data-automation={elementType}
    >
      <OptionsToRender />
    </Box>
  );
};

export default LinkSelect;
