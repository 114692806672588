import type { JobDetails } from 'src/store/jobdetails/jobdetails';

export const buildJobPostingMetadata = (jobDetails: JobDetails) => {
  const datePosted = jobDetails.job.listedAt?.dateTimeUtc;
  const sameAs = jobDetails.companyProfile?.overview?.website?.url;
  const logo = jobDetails.companyProfile?.branding?.logo;
  const state = jobDetails.gfjInfo?.location?.state;
  const region = jobDetails.gfjInfo?.location?.region;
  const postcode = jobDetails.gfjInfo?.location?.postcode;

  return {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    ...(datePosted ? { datePosted } : {}),
    description: jobDetails.job.content,
    hiringOrganization: {
      '@type': 'Organization',
      name: jobDetails.job.advertiser.name,
      ...(sameAs ? { sameAs } : {}),
      ...(logo ? { logo } : {}),
    },
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        ...(state ? { addressLocality: state } : {}),
        ...(region ? { addressRegion: region } : {}),
        ...(postcode ? { postalCode: postcode } : {}),
        addressCountry: jobDetails.gfjInfo?.location?.country,
      },
    },
    title: jobDetails.job.title,
    directApply: !jobDetails.job.isLinkOut,
    employmentType: jobDetails.gfjInfo?.workTypes?.label,
    identifier: {
      '@type': 'PropertyValue',
      name: jobDetails.job.advertiser.name,
      value: jobDetails.job.id,
    },
    validThrough: jobDetails.job.expiresAt.dateTimeUtc,
  };
};
