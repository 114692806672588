import { useCallback, useState } from 'react';

import { useCreateUnregisteredSavedSearch } from 'src/modules/hooks/SavedSearch';
import { useDispatch, useSelector } from 'src/store/react';
import {
  type JobMailPosition,
  type JobMailType,
  JOB_MAIL_BEGIN,
  selectSaveSearchStatus,
  selectSaveSearchEmailAddress,
} from 'src/store/saveSearch/saveSearch';
import { selectPageNumber } from 'src/store/selectors';

import type { UnregisteredSaveSearchFormValues } from './UnregisteredSaveSearch';

const mapPositionToJobMailType = ({
  position,
  pageNumber,
}: {
  position: JobMailPosition;
  pageNumber: number | null | undefined;
}): JobMailType => {
  let type: JobMailType = 'job-mail';
  if (position === 'top') {
    type = pageNumber === 1 ? 'job-mail-top-page-1' : 'job-mail-after-page-1';
  }
  return type;
};

const useUnregisteredSaveSearch = ({
  position,
}: {
  position: JobMailPosition;
}) => {
  const emailAddress = useSelector(selectSaveSearchEmailAddress);
  const status = useSelector(selectSaveSearchStatus);
  const pageNumber = useSelector(selectPageNumber);

  const jobMailType = mapPositionToJobMailType({ position, pageNumber });

  const dispatch = useDispatch();
  const [email, setEmail] = useState(emailAddress);
  const { onCreateUnregisteredSavedSearch } =
    useCreateUnregisteredSavedSearch();

  const onSubmit = useCallback(
    (formValues: UnregisteredSaveSearchFormValues) => {
      if (!formValues.jobMailLiteEmail) {
        return;
      }

      dispatch({ type: JOB_MAIL_BEGIN });
      setEmail(formValues.jobMailLiteEmail);
      onCreateUnregisteredSavedSearch({
        emailAddress: formValues.jobMailLiteEmail,
        jobMailType,
        jobMailPosition: position,
      });
    },
    [dispatch, onCreateUnregisteredSavedSearch, jobMailType, position],
  );

  return {
    email,
    emailAddress,
    status,
    onSubmit,
  };
};

export default useUnregisteredSaveSearch;
